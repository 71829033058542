@include htmlConditioner(false, 'pc') {
  .button {
    flex-shrink: 0;
    width: ptr(160);
  }
}

@include htmlConditioner(false, 'mo') {
  .button {
    width: unset;
  }
}
