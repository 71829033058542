@import "base";
@import '../../../../../shared/style/mini_button';
@import '../../../../../shared/style/code_input_animation';

@include htmlConditioner(false, 'pc') {
  .verify {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(80);

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(32);
    }

    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(8);
    }

    .guide_container {
      display: flex;
      width: 100%;
      gap: ptr(4);

      .guide_icon {
        padding-top: ptr(1);
      }
    }

    .button_container {
      display: flex;
      align-items: center;
      width: 100%;
      gap: ptr(16);
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .verify {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(80);

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(32);
    }

    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(8);
    }

    .guide_container {
      display: flex;
      width: 100%;
      gap: ptr(4);

      .guide_icon {
        padding-top: ptr(3);
      }
    }

    .button_container {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      align-items: center;
      width: 100%;
      margin-bottom: env(safe-area-inset-bottom, 0);
      padding: ptr(12) ptr(24);
      gap: ptr(16);

      .button {
        flex-shrink: 1;
        width: 100%;
      }
    }
  }
}
