@import "base";
html[device='pc'] {
  .page_wrapper {
    @include flex(center, center, column);

    padding: ptr(60);
  }

  .mypage {
    display: flex;
    flex-direction: column;
    width: ptr(512);
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: ptr(8);
    color: $gray-50;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: ptr(32);
    padding-top: ptr(32);
  }

  .profile_image {
    @include flex(center, center, column);

    gap: ptr(28);
  }

  .profile_image_description {
    color: $gray-50;
    text-align: center;
  }

  .field {
    display: flex;
    flex-direction: column;
    gap: ptr(8);
  }

  .field_description {
    color: $gray-50;
  }

  .field_description_info {
    display: flex;
    align-items: center;
    margin-top: ptr(8);
    color: $accent;
    gap: ptr(4);
  }

  .input_group_button {
    display: flex;
    gap: ptr(8);
    margin-top: ptr(4);
    margin-bottom: ptr(8);

    > button {
      flex-shrink: 0;
      width: ptr(160);
    }
  }

  .input_group_radio {
    display: flex;
    gap: ptr(24);
  }

  .button_group {
    display: flex;
    gap: ptr(16);

    > :first-child {
      flex-shrink: 0;
      width: ptr(160);
    }
  }

  .button_group_sns {
    display: flex;
    gap: ptr(12);
    margin-top: ptr(12);
  }

  .withdrawal {
    color: $gray-50;
  }

  .address_wrapper {
    display: flex;
    flex-direction: column;
    gap: ptr(4);

    .input_group_button {
      margin-bottom: 0;
    }
  }

  .address_layer {
    display: none;
    position: relative;
    width: 100%;
    height: ptr(300);
    margin: ptr(5) 0;
    border: 1px solid;
  }

  .sns_button_container {
    display: flex;
    flex-direction: column;
    gap: ptr(24);
  }
}

html[device='mo'] {
  .page {
    display: flex;
    flex-direction: column;
  }

  .mypage {
    display: flex;
    flex-direction: column;
    padding: ptr(24);
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: ptr(8);
    color: $gray-50;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: ptr(32);
    padding-top: ptr(32);
    padding-bottom: ptr(118);
  }

  .profile_image {
    @include flex(center, center, column);

    gap: ptr(28);
  }

  .profile_image_description {
    color: $gray-50;
    text-align: center;
  }

  .field {
    display: flex;
    flex-direction: column;
    gap: ptr(8);
  }

  .field:has(.button_group) {
    position: fixed;
    bottom: ptr(0);
    left: 0;
    width: 100%;
    padding: ptr(16);
    padding-bottom: ptr(34);
    border-top: 1px solid $gray-10;
    background-color: $white;

    .button_group {
      > :first-child {
        display: none;
      }
    }
  }

  .field_description {
    color: $gray-50;
  }

  .field_description_info {
    display: flex;
    align-items: flex-start;
    margin-top: ptr(8);
    color: $accent;
    gap: ptr(4);

    > span {
      padding-top: ptr(2);
    }
  }

  .input_group_button {
    display: flex;
    gap: ptr(8);
    margin-top: ptr(4);
    margin-bottom: ptr(8);

    > button {
      flex-shrink: 0;
      width: ptr(120);
    }
  }

  .input_group_radio {
    display: flex;
    gap: ptr(24);
  }

  .button_group {
    display: flex;
    gap: ptr(16);

    > :first-child {
      flex-shrink: 0;
      width: ptr(160);
    }
  }

  .button_group_sns {
    display: flex;
    gap: ptr(16);
    margin-top: ptr(12);
  }

  .withdrawal {
    color: $gray-50;
  }

  .address {
    display: flex;
  }

  .address_wrapper {
    display: flex;
    flex-direction: column;
    gap: ptr(4);

    .input_group_button {
      margin-bottom: 0;
    }
  }

  .address_layer {
    display: none;
    position: relative;
    width: 100%;
    height: ptr(300);
    margin: ptr(5) 0;
    border: 1px solid;
  }

  .sns_button_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
