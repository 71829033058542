@import "base";
@import '../../../../../shared/style/code_input_animation';

@include htmlConditioner(false, 'pc') {
  .modal_body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(24);
  }

  .field {
    display: flex;
    flex-direction: column;
    gap: ptr(8);

    &.hidden {
      display: none;
    }
  }

  .field:has(.input_group) {
    display: flex;
    flex-direction: column;
    gap: ptr(4);
  }

  .input_group {
    display: flex;
    gap: ptr(8);

    > button {
      flex-shrink: 0;
      width: ptr(160);
    }
  }

  .description_info {
    display: flex;
    align-items: center;
    color: $accent;
    gap: ptr(4);

    > div > span {
      flex-grow: 1;
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .modal_body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(24);
  }

  .field {
    display: flex;
    flex-direction: column;
    gap: ptr(8);

    &.hidden {
      display: none;
    }
  }

  .input_group {
    display: flex;
    gap: ptr(8);

    > button {
      flex-shrink: 0;
      width: unset;
    }
  }

  .description_info {
    display: flex;
    align-items: center;
    color: $accent;
    gap: ptr(4);

    > span {
      align-self: flex-start;
      padding-top: ptr(1.6);
    }

    > div > span {
      flex-grow: 1;
    }
  }
}
