@import "base";
.link {
  width: 100%;
}

@include htmlConditioner(false, 'pc') {
  .sns_signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: ptr(32);

    .button_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(16);

      .mini_button_container {
        display: flex;
        gap: ptr(16);
      }
    }
  }

  .signup_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ptr(16);

    .signup {
      display: flex;
      gap: ptr(16);
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .sns_signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: ptr(24);

    .button_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(24);

      .mini_button_container {
        display: flex;
        gap: ptr(8);
      }
    }
  }

  .signup_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ptr(16);

    .signup {
      display: flex;
      gap: ptr(16);
    }
  }
}
