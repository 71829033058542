@import "base";
@import '../../../../../shared/style/code_input_animation';
@import '../../../../../shared/style/mini_button';

@include htmlConditioner('Marpple') {
  .email_container {
    background-color: $gray_10;
  }
}

@include htmlConditioner('MarppleShop') {
  .email_container {
    background-color: $secondary;
  }
}

@include htmlConditioner(false, 'pc') {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(24);
    text-align: center;

    .email_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: ptr(10) ptr(16);
      border-radius: ptr(8);
      gap: ptr(16);
    }

    .sns_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: ptr(16);
      gap: ptr(16);
    }

    .sns_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ptr(32);

      .sns {
        display: flex;
        align-self: center;
        gap: ptr(8);
      }
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(24);
    padding: ptr(24) 0;
    text-align: center;

    .email_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: ptr(10) ptr(16);
      border-radius: ptr(8);
      gap: ptr(16);
    }

    .sns_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: ptr(16);
      gap: ptr(16);
      background-color: $gray_10;

      .guide {
        width: 100%;
        padding-bottom: ptr(8);
        text-align: center;
      }
    }

    .sns_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ptr(32);

      .sns {
        display: flex;
        align-self: center;
        gap: ptr(8);
      }
    }
  }
}
