@import "base";
@import '../../../../../shared/style/mini_button';

@include htmlConditioner(false, 'pc') {
  .guide_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: ptr(4);
  }

  .connect {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    gap: ptr(16);

    .button_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(8);
      padding-bottom: ptr(16);

      .button {
        width: 100%;
      }
    }

    .link_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: ptr(8);

      .divider {
        width: 1px;
        height: ptr(12);
        background-color: $gray_10;
      }
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .guide_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: ptr(4);
  }

  .connect {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    gap: ptr(16);

    .button_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(8);
      padding-bottom: ptr(16);

      .button {
        width: 100%;
      }
    }

    .link_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: ptr(8);

      .divider {
        width: 1px;
        height: ptr(12);
        background-color: $gray_10;
      }
    }
  }
}
