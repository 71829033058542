@import "base";
@import '../../../../../shared/style/code_input_animation';
@import '../../../../../shared/style/mini_button';

@include htmlConditioner(false, 'pc') {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(24);

    &.input_container {
      gap: ptr(4);
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(24);

    &.input_container {
      gap: ptr(8);
    }
  }
}
