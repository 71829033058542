@import "base";
@include htmlConditioner(false, 'pc') {
  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ptr(512);
    height: 100dvh;
    margin: auto;
    padding: ptr(120) ptr(80);
    gap: ptr(24);

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ptr(80);
      height: ptr(80);
    }

    .guide {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: ptr(32) 0;
      text-align: center;
      gap: ptr(8);
    }

    .services {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(16);

      .item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: ptr(16);
        border: 1px solid $gray_20;
        border-radius: ptr(12);
        gap: ptr(12);

        .logo {
          display: flex;
          align-items: center;
          justify-content: center;
          width: ptr(60);
          height: ptr(60);
          border-radius: ptr(8);
          color: $white;

          &[data-theme='NexonEssential'] {
            background-color: $black;
          }

          &[data-theme='Marpple'] {
            color: $black;
          }

          @each $name, $map in $themes {
            &[data-theme='#{$name}'] {
              background-color: get-theme-color($name, accent);
            }
          }
        }

        .text {
          display: flex;
          flex-direction: column;
          gap: ptr(2);
        }
      }
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100dvh;
    margin: auto;
    padding: ptr(120) ptr(24);
    gap: ptr(24);

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ptr(80);
      height: ptr(80);
    }

    .guide {
      width: 100%;
      padding: ptr(16) 0;
      text-align: center;
    }

    .services {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(16);

      .item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: ptr(16);
        border: 1px solid $gray_20;
        border-radius: ptr(12);
        gap: ptr(12);

        .logo {
          display: flex;
          align-items: center;
          justify-content: center;
          width: ptr(60);
          height: ptr(60);
          border-radius: ptr(8);
          color: $white;

          &[data-theme='NexonEssential'] {
            background-color: $black;
          }

          &[data-theme='Marpple'] {
            color: $black;
          }

          @each $name, $map in $themes {
            &[data-theme='#{$name}'] {
              background-color: get-theme-color($name, accent);
            }
          }
        }

        .text {
          display: flex;
          flex-direction: column;
          gap: ptr(2);
        }
      }
    }
  }
}
