@import "base";
@import '../../../../../shared/style/mini_button';

@include htmlConditioner(false, 'pc') {
  .signup {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(32);

    .info_container {
      display: flex;
      width: 100%;
      gap: ptr(16);

      .info_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ptr(16);
      }

      .info_text_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ptr(2);
      }

      .info_data_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ptr(4);
      }

      .info_data_row {
        display: flex;
        width: 100%;
        gap: ptr(16);

        .title {
          min-width: ptr(92);
        }

        .email {
          word-break: break-all;
        }
      }

      .nickname_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ptr(4);

        &[data-row='true'] {
          flex-direction: row;
          align-items: center;
          color: $gray_50;
        }
      }
    }

    .agreement_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: ptr(16) 0;

      .all {
        width: 100%;
        padding-bottom: ptr(12);
        border-bottom: 1px solid $gray_20;
      }

      .item_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: ptr(12);
        gap: ptr(12);
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: ptr(8);

        .see_more {
          flex-shrink: 0;
        }
      }
    }

    .button_container {
      display: flex;
      align-items: center;
      width: 100%;
      gap: ptr(16);
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .signup {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(32);

    .info_container {
      display: flex;
      width: 100%;
      gap: ptr(16);

      .info_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ptr(16);
      }

      .info_text_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ptr(2);
      }

      .info_data_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ptr(4);
      }

      .info_data_row {
        display: flex;
        width: 100%;
        gap: ptr(16);

        .title {
          min-width: ptr(92);
        }

        .email {
          word-break: break-all;
        }
      }

      .nickname_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: ptr(4);

        &[data-row='true'] {
          flex-direction: row;
          color: $gray_50;
        }

        .info_icon {
          padding: ptr(2) 0;
        }
      }
    }

    .agreement_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: ptr(16) 0;

      .all {
        width: 100%;
        padding-bottom: ptr(16);
        border-bottom: 1px solid $gray_20;
      }

      .item_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: ptr(16);
        gap: ptr(8);
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: ptr(8);

        .see_more {
          flex-shrink: 0;
        }
      }
    }

    .button_container {
      display: flex;
      align-items: center;
      width: 100%;
      gap: ptr(16);

      > button {
        flex: 1 0 0;
      }
    }
  }
}
