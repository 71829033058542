@import "base";
@include htmlConditioner(false, 'pc') {
  .wrapper {
    display: flex;
    position: relative;
    width: 100%;
  }

  .list {
    display: flex;
    position: absolute;
    z-index: 1;
    flex-direction: column;
    width: 100%;
    border: 1px solid $gray-20;
    background-color: $white;

    &[data-is-show='false'] {
      display: none;
    }
  }

  .item {
    padding: ptr(8);

    &[data-selected='true'] {
      background-color: $gray-10;
    }

    &:hover {
      background-color: $gray-10;
      cursor: pointer;
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .wrapper {
    display: flex;
    position: relative;
    width: 100%;
  }

  .list {
    display: flex;
    position: absolute;
    z-index: 1;
    flex-direction: column;
    width: 100%;
    border: 1px solid $gray-20;
    background-color: $white;

    &[data-is-show='false'] {
      display: none;
    }
  }

  .item {
    padding: ptr(8);

    &[data-selected='true'] {
      background-color: $gray-10;
    }

    &:hover {
      background-color: $gray-10;
      cursor: pointer;
    }
  }
}
