@import "base";
@include htmlConditioner(false, 'pc') {
  .image_preview {
    position: relative;
    width: ptr(160);
    height: ptr(160);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: ptr(999);
    }
  }

  .image_add {
    display: flex;
    position: absolute;
    bottom: ptr(-12);
    left: 50%;
    padding: ptr(4) ptr(12);
    transform: translateX(-50%);
    border-radius: ptr(8);
    background-color: $accent;
    color: $white;
  }

  .image_remove {
    display: flex;
    position: absolute;
    top: ptr(8);
    right: 0;
    padding: ptr(8);
    border-radius: ptr(999);
    background-color: $black;

    span {
      width: ptr(12);
      height: ptr(12);
      color: $white;
    }

    &.hidden {
      display: none;
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .image_preview {
    position: relative;
    width: ptr(160);
    height: ptr(160);
    border-radius: ptr(999);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: ptr(999);
    }
  }

  .image_add {
    display: flex;
    position: absolute;
    bottom: ptr(-12);
    left: 50%;
    padding: ptr(4) ptr(12);
    transform: translateX(-50%);
    border-radius: ptr(8);
    background-color: $accent;
    color: $white;
  }

  .image_remove {
    display: flex;
    position: absolute;
    top: ptr(8);
    right: 0;
    padding: ptr(8);
    border-radius: ptr(999);
    background-color: $black;

    span {
      width: ptr(12);
      height: ptr(12);
      color: $white;
    }

    &.hidden {
      display: none;
    }
  }
}
