@import "base";
.button {
  cursor: pointer;
}

.page {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@include htmlConditioner(false, 'pc') {
  .container {
    width: ptr(512);
    margin: ptr(160) auto ptr(48);
    gap: ptr(80);
  }

  .section {
    gap: ptr(64);
  }
}

@include htmlConditioner(false, 'mo') {
  .container {
    width: 100%;
    max-width: 554px;
    padding: ptr(48) ptr(24);
    gap: ptr(48);
  }

  .section {
    gap: ptr(48);
  }
}
