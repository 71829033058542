@import "base";
@include htmlConditioner(false, 'pc') {
  .popup {
    display: flex;
    position: absolute;
    z-index: 10000001;
    top: ptr(200);
    right: ptr(40);
    flex-direction: column;
    width: ptr(420);
    overflow: hidden;
    border-radius: ptr(16);

    @media (width > 1440px) {
      left: 70%;
    }

    @media (height < 1024px) {
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    @media (height < 686px) {
      top: ptr(80);
      transform: translate3d(0, 0, 0);
    }

    .image {
      width: 100%;
      height: ptr(560);

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bottom_bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: ptr(12) ptr(16);
      background-color: $black;
    }

    .link {
      cursor: pointer;
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .popup {
    display: flex;
    position: absolute;
    z-index: 10000001;
    top: 50%;
    left: 50%;
    flex-direction: column;
    width: 86.42dvw;
    overflow: hidden;
    transform: translate3d(-50%, -50%, 0);
    border-radius: ptr(16);

    .image {
      width: 100%;
      height: 120dvw;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bottom_bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: ptr(12) ptr(16);
      background-color: $black;
    }

    .link {
      cursor: pointer;
    }
  }
}
