@import "base";
@include htmlConditioner(false, 'pc') {
  .signin {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(32);

    .button_container,
    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(16);
    }

    .modal_button_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: ptr(8);

      .divider {
        width: 1px;
        height: ptr(12);
        background-color: $gray_10;
      }
    }
  }

  .sns_signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ptr(16);

    .button_container {
      display: flex;
      gap: ptr(12);
    }
  }

  .signup {
    display: flex;
    gap: ptr(16);
  }

  .theme_image {
    width: 41.6667%;
    max-width: 800px;
    height: 100%;
    min-height: 100vh;
    transition:
      width 300ms ease-out,
      opacity 300ms ease-out;
    object-fit: cover;
    object-position: center center;

    @media (width <= 1440px) {
      width: 0;
      opacity: 0;
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .signin {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(24);

    .button_container,
    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(16);
    }

    .modal_button_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: ptr(8);

      .divider {
        width: 1px;
        height: ptr(12);
        background-color: $gray_10;
      }
    }
  }

  .sns_signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ptr(16);

    .button_container {
      display: flex;
      gap: ptr(12);
    }
  }

  .signup {
    display: flex;
    gap: ptr(16);
  }
}

.button_wrapper {
  position: relative;
  width: fit-content;
  height: ptr(48);

  .current_sns {
    display: flex;
    position: absolute;
    bottom: ptr(-4);
    left: 50%;
    flex-direction: column;
    align-items: center;
    transform: translate3d(-50%, 100%, 0);

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: ptr(36);
      margin-top: -1px;
      padding: ptr(4) ptr(16);
      border-radius: ptr(999);
      white-space: nowrap;

      @include conditionThemify('Marpple') {
        background-color: $accent;
        color: $white;
      }

      @include conditionThemify('MarppleShop') {
        background-color: $secondary;
        color: $primary;
      }
    }
  }
}

.google_button {
  position: absolute;
  z-index: 1;
  inset: 0;
  opacity: 0;
}
