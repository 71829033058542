@import "base";
@import '../../../../../shared/style/code_input_animation';
@import '../../../../../shared/style/mini_button';

@include htmlConditioner(false, 'pc') {
  .signup {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(32);

    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(16);

      .input_wrapper {
        display: flex;
        width: 100%;
        gap: ptr(8);
      }
    }

    .agreement_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: ptr(16) 0;

      .all {
        width: 100%;
        padding-bottom: ptr(12);
        border-bottom: 1px solid $gray_20;
      }

      .item_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: ptr(12);
        gap: ptr(12);
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: ptr(8);

        .see_more {
          flex-shrink: 0;
        }
      }
    }

    .button_container {
      display: flex;
      align-items: center;
      width: 100%;
      gap: ptr(16);
    }
  }
}

@include htmlConditioner(false, 'mo') {
  .signup {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ptr(32);

    .input_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: ptr(24);

      .input_wrapper {
        display: flex;
        width: 100%;
        gap: ptr(8);
      }
    }

    .agreement_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: ptr(16) 0;

      .all {
        width: 100%;
        padding-bottom: ptr(16);
        border-bottom: 1px solid $gray_20;
      }

      .item_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: ptr(16);
        gap: ptr(8);
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: ptr(8);

        .see_more {
          flex-shrink: 0;
        }
      }
    }

    .button_container {
      display: flex;
      align-items: center;
      width: 100%;
      gap: ptr(16);
    }
  }
}
